// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as PfIcon from "../../../pf-icon/src/PfIcon.bs.js";
import * as $$String from "../../../../../../node_modules/rescript/lib/es6/string.js";
import * as Js_math from "../../../../../../node_modules/rescript/lib/es6/js_math.js";
import * as Js_string from "../../../../../../node_modules/rescript/lib/es6/js_string.js";
import * as Caml_array from "../../../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Belt_Option from "../../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_exceptions from "../../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import FuzzySearch from "../utils/fuzzySearch";
import * as Webapi__Dom__Element from "../../../../../../node_modules/rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.bs.js";

import "./MultiselectDropdown.css"
;

function fuzzySearch(prim0, prim1) {
  return FuzzySearch(prim0, prim1);
}

function str(prim) {
  return prim;
}

var RootElementMissing = /* @__PURE__ */Caml_exceptions.create("MultiselectDropdown.DomUtils.RootElementMissing");

function focus(id) {
  var el = document.getElementById(id);
  var tmp;
  if (el == null) {
    throw {
          RE_EXN_ID: RootElementMissing,
          _1: id,
          Error: new Error()
        };
  }
  tmp = el;
  Belt_Option.map(Webapi__Dom__Element.asHtmlElement(tmp), (function (prim) {
          prim.focus();
        }));
}

var DomUtils = {
  RootElementMissing: RootElementMissing,
  focus: focus
};

function Make(Selectable) {
  var search = function (searchString, selections) {
    var searchSelection = selections.map(function (s, i) {
          return {
                  index: i,
                  text: Curry._1(Selectable.searchString, s)
                };
        });
    var results = FuzzySearch($$String.lowercase_ascii(searchString), searchSelection);
    return results.map(function (searchItem) {
                return Caml_array.get(selections, searchItem.index);
              });
  };
  var selectionTitle = function (selection) {
    var value = Curry._1(Selectable.value, selection);
    var label = Curry._1(Selectable.label, selection);
    if (label !== undefined) {
      return "Pick " + (label + (": " + value));
    } else {
      return "Pick " + value;
    }
  };
  var tagPillClasses = function (color, showHover) {
    var bgColor;
    switch (color) {
      case "blue" :
          bgColor = "bg-blue-100";
          break;
      case "focusColor" :
          bgColor = "bg-focusColor-100";
          break;
      case "gray" :
          bgColor = "bg-gray-100";
          break;
      case "green" :
          bgColor = "bg-green-100";
          break;
      case "orange" :
          bgColor = "bg-orange-100";
          break;
      case "primary" :
          bgColor = "bg-primary-100";
          break;
      case "red" :
          bgColor = "bg-red-100";
          break;
      case "yellow" :
          bgColor = "bg-yellow-100";
          break;
      default:
        bgColor = "bg-orange-100";
    }
    var textColor;
    switch (color) {
      case "blue" :
          textColor = "text-blue-800";
          break;
      case "focusColor" :
          textColor = "text-focusColor-800";
          break;
      case "gray" :
          textColor = "text-gray-800";
          break;
      case "green" :
          textColor = "text-green-800";
          break;
      case "orange" :
          textColor = "text-orange-800";
          break;
      case "primary" :
          textColor = "text-primary-800";
          break;
      case "red" :
          textColor = "text-red-800";
          break;
      case "yellow" :
          textColor = "text-yellow-800";
          break;
      default:
        textColor = "text-orange-800";
    }
    return "rounded text-sm  font-semibold overflow-hidden " + (bgColor + " " + textColor + " " + (
              showHover ? "px-2 py-px hover:saturate-50 focus:saturate-50" : "inline-flex"
            ));
  };
  var applyFilter = function (selection, onSelect, id, $$event) {
    $$event.preventDefault();
    Curry._1(onSelect, selection);
    focus(id);
  };
  var showOptions = function (options, onSelect, id, labelSuffix, loading) {
    if (loading) {
      return [React.createElement("div", {
                    className: "px-4"
                  }, React.createElement("div", {
                        className: "px-4"
                      }, React.createElement("div", {
                            className: "skeleton-body-container w-full pb-4 mx-auto"
                          }, React.createElement("div", {
                                className: "skeleton-body-wrapper px-3 lg:px-0"
                              }, React.createElement("div", {
                                    className: "skeleton-placeholder__line-sm mt-4 w-1/2 skeleton-animate"
                                  }), React.createElement("div", {
                                    className: "skeleton-placeholder__line-sm mt-4 w-3/4 skeleton-animate"
                                  })))))];
    } else {
      return $$Array.mapi((function (index, selection) {
                    var label = Curry._1(Selectable.label, selection);
                    return React.createElement("button", {
                                key: String(index),
                                "aria-label": Curry._1(Selectable.searchString, selection),
                                className: "flex text-sm px-4 py-1 items-center w-full hover:bg-gray-50 focus:outline-none focus:bg-gray-50",
                                title: selectionTitle(selection),
                                onClick: (function (param) {
                                    return applyFilter(selection, onSelect, id, param);
                                  })
                              }, label !== undefined ? React.createElement("span", {
                                      className: "me-2 shrink-0 w-1/3 sm:w-auto md:w-1/3 ltr:text-right rtl:text-left"
                                    }, label + labelSuffix) : null, React.createElement("span", {
                                    className: tagPillClasses(Curry._1(Selectable.color, selection), true)
                                  }, Curry._1(Selectable.value, selection)));
                  }), options);
    }
  };
  var searchResult = function (searchInput, unselected, labelSuffix, loading, id, onSelect) {
    var normalizedString = Js_string.replaceByRe(new RegExp("\\s+", "g"), " ", searchInput.trim());
    var options = normalizedString === "" ? [] : search(normalizedString, unselected);
    return showOptions(options, onSelect, id, labelSuffix, loading);
  };
  var removeSelection = function (onDeselect, selection, $$event) {
    $$event.preventDefault();
    return Curry._1(onDeselect, selection);
  };
  var showSelected = function (onDeselect, labelSuffix, selected) {
    return $$Array.mapi((function (index, selection) {
                  var value = Curry._1(Selectable.value, selection);
                  var label = Curry._1(Selectable.label, selection);
                  return React.createElement("div", {
                              key: String(index),
                              className: tagPillClasses(Curry._1(Selectable.color, selection), false) + " "
                            }, React.createElement("span", {
                                  className: "px-2 py-px text-xs leading-[unset]"
                                }, label !== undefined ? label + (labelSuffix + value) : value), React.createElement("button", {
                                  "aria-label": "Remove selection: " + value,
                                  className: "bg-black bg-opacity-5 text-red-700 px-2 py-px text-xs focus:outline-none hover:bg-red-400 hover:text-white flex items-center focus:bg-red-400 focus:text-white",
                                  title: "Remove selection: " + value,
                                  onClick: (function (param) {
                                      return removeSelection(onDeselect, selection, param);
                                    })
                                }, React.createElement(PfIcon.make, {
                                      className: "if i-times-regular"
                                    })));
                }), selected);
  };
  var onWindowClick = function (showDropdown, setShowDropdown, _event) {
    if (showDropdown) {
      return Curry._1(setShowDropdown, (function (param) {
                    return false;
                  }));
    }
    
  };
  var toggleDropdown = function (setShowDropdown, $$event) {
    $$event.stopPropagation();
    return Curry._1(setShowDropdown, (function (showDropdown) {
                  return !showDropdown;
                }));
  };
  var wrapper = function (children) {
    return React.createElement("div", {
                className: "multiselect-dropdown__search-dropdown w-full absolute border border-gray-300 bg-white mt-1 rounded-lg shadow-lg py-2 z-50",
                role: "listbox"
              }, React.createElement("p", {
                    className: "text-gray-600 italic mx-4 text-xs border-b pb-1 mb-2"
                  }, "Vorschläge:"), children);
  };
  var showHint = function (hint) {
    return React.createElement("p", {
                className: "font-normal text-xs px-4 py-2 -mb-2 rounded-b-lg bg-gray-50 mt-2  border-t"
              }, hint);
  };
  var MultiselectDropdown$Make = function (Props) {
    var id = Props.id;
    var placeholderOpt = Props.placeholder;
    var onChange = Props.onChange;
    var value = Props.value;
    var unselected = Props.unselected;
    var selected = Props.selected;
    var onSelect = Props.onSelect;
    var onDeselect = Props.onDeselect;
    var labelSuffixOpt = Props.labelSuffix;
    var emptyMessageOpt = Props.emptyMessage;
    var hint = Props.hint;
    var defaultOptionsOpt = Props.defaultOptions;
    var loadingOpt = Props.loading;
    var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Suche";
    var labelSuffix = labelSuffixOpt !== undefined ? labelSuffixOpt : ": ";
    var emptyMessage = emptyMessageOpt !== undefined ? emptyMessageOpt : "No results found";
    var defaultOptions = defaultOptionsOpt !== undefined ? defaultOptionsOpt : [];
    var loading = loadingOpt !== undefined ? loadingOpt : false;
    var match = React.useState(function () {
          if (id !== undefined) {
            return id;
          } else {
            return "re-multiselect-" + (Date.now().toString() + ("-" + String(Js_math.random_int(100000, 999999))));
          }
        });
    var inputId = match[0];
    var match$1 = React.useState(function () {
          return false;
        });
    var setShowDropdown = match$1[1];
    var showDropdown = match$1[0];
    React.useEffect((function () {
            var curriedFunction = function (param) {
              return onWindowClick(showDropdown, setShowDropdown, param);
            };
            var removeEventListener = function (param) {
              window.removeEventListener("click", curriedFunction);
            };
            if (showDropdown) {
              window.addEventListener("click", curriedFunction);
              return removeEventListener;
            } else {
              removeEventListener(undefined);
              return ;
            }
          }), [showDropdown]);
    var results = searchResult(value, unselected, labelSuffix, loading, inputId, onSelect);
    var tmp;
    if (showDropdown) {
      tmp = results.length !== 0 ? wrapper(results) : (
          defaultOptions.length !== 0 ? (
              hint !== undefined ? wrapper(React.createElement("div", undefined, showOptions(defaultOptions, onSelect, inputId, labelSuffix, loading), showHint(hint))) : wrapper(showOptions(defaultOptions, onSelect, inputId, labelSuffix, loading))
            ) : (
              hint !== undefined ? wrapper(showHint(hint)) : (
                  value === "" ? null : wrapper(emptyMessage)
                )
            )
        );
    } else {
      var match$2 = value.trim();
      tmp = match$2 === "" ? null : (
          results.length !== 0 ? wrapper(results) : wrapper(emptyMessage)
        );
    }
    return React.createElement("div", {
                className: "w-full relative"
              }, React.createElement("div", undefined, React.createElement("div", {
                        className: "bg-gray-50 flex flex-wrap gap-2 items-center text-sm bg-white border border-gray-300 rounded-md w-full p-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-focusColor-500"
                      }, showSelected(onDeselect, labelSuffix, selected), React.createElement("input", {
                            className: "flex-1 grow appearance-none bg-transparent border-none text-gray-600 leading-snug focus:outline-none placeholder-gray-500",
                            id: inputId,
                            role: "combobox",
                            autoComplete: "off",
                            placeholder: placeholder,
                            type: "search",
                            value: value,
                            onChange: (function (e) {
                                Curry._1(onChange, e.target.value);
                              }),
                            onClick: (function (param) {
                                Curry._1(setShowDropdown, (function (s) {
                                        return !s;
                                      }));
                              })
                          }))), React.createElement("div", undefined), tmp);
  };
  return {
          search: search,
          selectionTitle: selectionTitle,
          tagPillClasses: tagPillClasses,
          applyFilter: applyFilter,
          showOptions: showOptions,
          searchResult: searchResult,
          removeSelection: removeSelection,
          showSelected: showSelected,
          onWindowClick: onWindowClick,
          toggleDropdown: toggleDropdown,
          wrapper: wrapper,
          showHint: showHint,
          make: MultiselectDropdown$Make
        };
}

export {
  fuzzySearch ,
  str ,
  DomUtils ,
  Make ,
}
/*  Not a pure module */
